import { createReducer } from "@reduxjs/toolkit";
import {
  BUSINESS_REQUIRE_LOADING,
  BUSINESS_REQUIRE_ERROR,
  BUSINESS_REQUIRE_LIST,
} from "../actions/types";

const initialState = {
  error: "",
  loading: false,
  data: [],
};

const businessRequireReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(BUSINESS_REQUIRE_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(BUSINESS_REQUIRE_ERROR, (state, { payload }) => {
      state.error = payload;
    })
    .addCase(BUSINESS_REQUIRE_LIST, (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    });
});

export default businessRequireReducer;
