import { Navigate, Route, Routes } from "react-router-dom";
import { lazy } from "react";

import SuspenseComponent from "../Components/SuspenseComponent";

const Main = lazy(() => import("../Layout/Main"));

export const Router = ({ mode, changeMode }) => {
  return (
    <Routes>
      <Route path="/*" element={<Main />} />

      <Route
        path="404"
        element={<SuspenseComponent>{/* <NotFound /> */}</SuspenseComponent>}
      />
      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
  );
};

export default Router;
