import { createReducer } from "@reduxjs/toolkit";
import { APPS_ERROR, APPS_LOADING, APPS_LIST } from "../actions/types";

const initialState = {
  error: "",
  loading: false,
  data: [],
};

const appsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(APPS_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(APPS_ERROR, (state, { payload }) => {
      state.error = payload;
    })
    .addCase(APPS_LIST, (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    });
});

export default appsReducer;
