import { createReducer } from "@reduxjs/toolkit";
import { ADD_THEME, THEME_ERROR, THEME_LOADING } from "../actions/types";

const initialState = {
  error: "",
  loading: false,
  data: [],
};

const themeReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(THEME_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(THEME_ERROR, (state, { payload }) => {
      state.error = payload;
    })
    .addCase(ADD_THEME, (state, { payload }) => {
      state.data.push(payload);
      state.loading = false;
    });
});

export default themeReducer;
