import { configureStore } from "@reduxjs/toolkit";
import thunkMiddleware from "redux-thunk";
import {
  auth,
  app,
  stores,
  industry,
  packages,
  category,
  categoryApp,
  fullApp,
  FAQ,
  PrivacyPolicy,
  terms,
  industries,
  wizardPackages,
  entities,
  businessRequire,
  order,
  pages,
  categoryApps,
  aboutUs,
  seo,
  objectives,
  templateApps,
  palettes,
} from "./reducers";

const store = configureStore({
  reducer: {
    order,
    auth,
    app,
    stores,
    industry,
    packages,
    category,
    categoryApp,
    categoryApps,
    fullApp,
    FAQ,
    PrivacyPolicy,
    terms,
    industries,
    wizardPackages,
    entities,
    businessRequire,
    pages,
    aboutUs,
    seo,
    objectives,
    templateApps,
    palettes,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(thunkMiddleware);
  },
  devTools: process.env.NODE_ENV === "production" ? false : true,
});

export default store;
