import { createReducer } from "@reduxjs/toolkit";
import {
  PALETTES_ERROR,
  PALETTES_LIST,
  PALETTES_LOADING,
} from "../actions/types";

const initialState = {
  error: "",
  loading: false,
  data: [],
};

const palettesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(PALETTES_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(PALETTES_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(PALETTES_LIST, (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    });
});

export default palettesReducer;
