import { createReducer } from "@reduxjs/toolkit";
import {
  OBJECTIVES_LOADING,
  OBJECTIVES_ERROR,
  OBJECTIVES_LIST,
} from "../actions/types";

const initialState = {
  error: "",
  loading: false,
  data: [],
  allData: [],
};

const objectiveReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(OBJECTIVES_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(OBJECTIVES_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(OBJECTIVES_LIST, (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    });
});

export default objectiveReducer;
