import { createReducer } from "@reduxjs/toolkit";
import {
  TEMP_APPS_LOADING,
  TEMP_APPS_ERROR,
  TEMP_APPS_LIST,
  TEMP_APP_SHOW,
} from "../actions/types";

const initialState = {
  error: "",
  loading: false,
  data: [],
  singleApp: {},
};

const templateAppsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(TEMP_APPS_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(TEMP_APPS_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(TEMP_APPS_LIST, (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    })
    .addCase(TEMP_APP_SHOW, (state, { payload }) => {
      state.singleApp = payload[0];
      state.loading = false;
    });
});

export default templateAppsReducer;
